SOM.Products = {

    init: function () {

        // $('.products-index').jscroll({
        //     autoTrigger: false,
        //     padding: 20,
        //     nextSelector: 'a.scroll-next:last',
        //     loadingHtml: '<div class="text-center"><i class="fa fa-spinner fa-5x fa-spin"></i></div>'
        // });

        $('.products-index a.scroll-next').click(function () {

            $('.products-index').jscroll({
                autoTrigger: true,
                padding: 20,
                nextSelector: 'a.scroll-next:last',
                loadingHtml: '<div class="text-center"><i class="fa fa-spinner fa-5x fa-spin"></i></div>'
            });

            window.scrollBy(0,10);

            return false;
        });
        
    },

};