SOM.Payment = {

    init: function () {
        var _this = this;

        $('#billing-address').on('hide.bs.collapse', function () {
            $('#unique_billing_address').val(0);
            console.log('hide');
        });

        $('#billing-address').on('show.bs.collapse', function () {
            $('#unique_billing_address').val(1);
            console.log('show');
        });

        $('#payment-form').submit(function (event) {

            var $form = $(this);

            // Disable the submit button to prevent repeated clicks
            $form.find('button').prop('disabled', true);

            Stripe.card.createToken($form, _this.StripeResponseHandler);

            // Prevent the form from submitting with the default action
            return false;
        });

        $('.local-cc-helper').click (function (event) {

            var $form = $('#payment-form');
            var number = $(this).data('number');
            var month = $(this).data('month') ? $(this).data('month') : 12;
            var year = $(this).data('year') ? $(this).data('year') : 2025;
            var cvc = $(this).data('cvc') ? $(this).data('cvc') : 999;

            $form.find('.form-cc-number').val(number);
            $form.find('.form-cc-month').val(month);
            $form.find('.form-cc-year').val(year);
            $form.find('.form-cc-cvc').val(cvc);

            return false;
        });
    },

    StripeResponseHandler: function (status, response) {

        var $form = $('#payment-form');

        if (response.error) {
            // Show the errors on the form
            $form.find('.stripe-errors').text(response.error.message).show();
            $form.find('button').prop('disabled', false);
        } else {
            // response contains id and card, which contains additional card details
            var token = response.id;
            // Insert the token into the form so it gets submitted to the server
            $form.append($('<input type="hidden" name="stripeToken" />').val(token));
            // and submit

            $form.get(0).submit();
        }
    }

};