var SOM = SOM || {};

$(document).ready(function(){
    SOM.General.init();
    SOM.Loading.init();
    SOM.Payment.init();
    SOM.Products.init();
    SOM.Search.init();
    SOM.Shops.init();

    var swiper = new Swiper('.swiper-container', {
        // pagination: '.swiper-pagination',
        // paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        spaceBetween: 30,
        useCSS3Transforms: false,
        loop: false
    });

});

$(window).load(function(){

});