SOM.Search = {

    init: function()
    {

        var _this = this;

        this.dropdown = $('#search-modes-dropdown-menu .dropdown-toggle');

        /**
         * Update "mode" button text when radio button changes
         * Close dropdown when radio button is clicked
         */
        $('#search-modes-dropdown-menu input').on('change', function(e) {

            var mode = $('input[name=mode]:checked', '#search-modes-dropdown-menu').val();

            if (mode == 'places') {
                $('#search-modes-dropdown-menu-label').html('Places');
            } else if (mode == 'products') {
                $('#search-modes-dropdown-menu-label').html('Products');
            } else if (mode == 'shops') {
                $('#search-modes-dropdown-menu-label').html('Shops');
            }

            $('#search-query').focus();
            $(document).trigger('click.bs.dropdown.data-api');
        });

        /**
         * Open dropdown when clicking into search field
         */
        $('#search-query').on('click', function(e) {
            e.stopPropagation();
            _this.dropdown.dropdown('toggle');
            $('#search-query').focus();
        });

    },

};