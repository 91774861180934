SOM.General = {

    init: function()
    {
        jQuery('.goesAway').on('click', function () {
            jQuery(this).hide();
            jQuery(this).attr('style','display:none !important;');
        });

        $('body').on('click', '.outside-form-submit', function(event){
            event.preventDefault();
            var thisMatchingForm = $(this).data('target');
            $('body').find('form#' + thisMatchingForm).submit();
        });
    }

};